import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { Container, H3, P } from "@util/standard";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT, colors } from "@util/constants";
import { Button } from "@sub";

const Wrapper = styled(Container)`
  margin: 0;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: ${colors.washedBlueFaded};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    height: 100%;
    margin-top: 100px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 50px;
  }
`;

const GlobalStyle = createGlobalStyle`
  .header-wrapper, body {
    background-color:${colors.washedBlueFaded}
  }
`;

export default function NotFoundPage() {
  return (
    <Wrapper>
      <GlobalStyle />
      <Container flexDirection="column" margin="150px auto">
        <H3 margin="0 0 20px" fontSize={50}>
          Well, that was a fail.
        </H3>
        <P margin="0 0 20px 0" fontSize={25}>
          It looks like you reached a page that doesn't exist.
        </P>
        <P margin="0 0 10px" fontSize={25} lineHeight="1em">
          Please click the button below to return to our home page.
        </P>
        <P margin="0 0 30px" fontSize={25} lineHeight="1em">
          In the meantime we'll keep looking for our lost page.
        </P>

        <Button theme="base" text="Return to home" linkTo={"/"} />
      </Container>
    </Wrapper>
  );
}
